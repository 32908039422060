import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  HeroSection,
  Wrapper,
  SecuritySection,
  CtaButton,
  MembershipSection,
  BecomeMemberSection,
  WhatsIncludedSection,
  WhyVozziSection,
} from './petrolLanding.styles';
import { navigateTo } from '../../../components/functional/Navigate';
import { NewStandardPageLayout } from '../../../layouts/newStadardPage.layout';
import { COUNTRY } from '../../../configs/env';

const petrolLandingPage: React.FC = () => {
  const { t, i18n } = useTranslation();

  const LANG = i18n.language;

  const metaOgImg = `/img/petrolLanding/Petrol-Meta-Image.jpg`;

  const meta_: any = {
    hr: {
      title: 'Title',
      description: 'description',
    },
    si: {
      title: 'VOZZi in Petrol klub – pomoč na cesti z enim klikom',
      description:
        'VOZZi vam zagotavlja varnost na cesti v celotni regiji skozi vse leto, Petrol pa zlate ugodnosti, ki jih lahko koristite na Petrolovih bencinskih servisih.',
    },
  };

  const meta = meta_[COUNTRY];

  const og = {
    image: metaOgImg,
  };

  const cta_1_button_link = COUNTRY === 'si' ? 'https://link.vozzi.app/yLEcPSS2CCb' : `https://link.vozzi.app/dRMY9nG4CCb`;
  const cta_2_button_link = COUNTRY === 'si' ? 'https://link.vozzi.app/rAMOEB42CCb' : `https://link.vozzi.app/B9SJXUL4CCb`;
  const cta_3_button_link = COUNTRY === 'si' ? 'https://link.vozzi.app/rkgLJ292CCb' : `https://link.vozzi.app/i6DSQAS4CCb`;
  const cta_4_button_link = COUNTRY === 'si' ? 'https://link.vozzi.app/6vbGN0c4CCb' : `https://link.vozzi.app/DtfwN624CCb`;
  const cta_5_button_link = COUNTRY === 'si' ? 'https://link.vozzi.app/GcESbyp4CCb' : `https://link.vozzi.app/0a6CTC74CCb`;

  return (
    <NewStandardPageLayout
      nav={false}
      footerTopSection={false}
      fullWidth={true}
      mainHeading={t('career_MainHeading')}
      backgroundColor="white"
      backgroundImage="bg/career@3x.jpg"
      backgroundImageMobile="bg/career-mobile@3x.jpg"
      og={og}
      meta={meta}
    >
      <HeroSection>
        <Wrapper>
          <div className="logo-section">
            <a href={`/${LANG}`}>
              <img className="logo-vozzi" src="/img/petrolLanding/vozzi-logo.svg" alt="VOZZi logo" />
            </a>
            <a href={`https://www.petrol.${COUNTRY === 'si' ? 'si' : 'hr'}/petrol-klub`}>
              <img className="logo-petrol" src="/img/petrolLanding/petrol-logo.svg" alt="Petrol Klub logo" />
            </a>
          </div>
          <div className="hero-images">
            <img className="gold-box" src="/img/petrolLanding/gold-box.png" />
            <img
              className="gold-phone"
              src="/img/petrolLanding/gold-phone.png"
              alt="Prikaz mobilnega telefona, na katerem je prikazana aplikacija VOZZi za pomoč na cesti. Iz telefona se izvaja kartica VOZZi Petrol Klub Regija SLO.
"
            />
          </div>
        </Wrapper>
      </HeroSection>
      <SecuritySection>
        <Wrapper>
          <h2>VOZZi in Petrol klub – pomoč na cesti z enim klikom</h2>
          <div className="text-and-qr-wrapper">
            <p>
              Izberite enega od paketov pomoči na cesti VOZZi Petrol Klub in se odpravite na pot. VOZZi vam zagotavlja varnost na cesti v
              celotni regiji skozi vse leto, Petrol pa zlate ugodnosti, ki jih lahko koristite na Petrolovih bencinskih servisih. Z nakupom
              enega od paketov pomoči na cesti VOZZi Petrol klub prejmete na Petrol klub kartico kupon v višini 7 € ali 13 €.  Namestite
              aplikacijo VOZZi in izvedite več!
            </p>
            <CtaButton onClick={() => navigateTo(cta_1_button_link)}>Postanite član kluba</CtaButton>
            <div className="qr-section">
              <img src="/img/petrolLanding/qr-code-1-sl.svg" alt="qr-code" />
              <p className="qr-text">Postanite član kluba</p>
            </div>
          </div>
        </Wrapper>
      </SecuritySection>
      <MembershipSection>
        <Wrapper>
          <h2>Postanite član Petrol kluba, sklenite enega izmed VOZZi Petrol klub paketov in prejmite:</h2>
          <img
            className="member-card"
            src="/img/petrolLanding/petrol-club-member-card.png"
            alt="Prikaz telefona, na katerem je zemljevid aplikacije VOZZi za pomoč na cesti. Izhaja 3D logotip VOZZi. Prikaz kartice zvestobe Petrol Klub."
          />
          <ul>
            <li>
              Varnost na cesti 12 mesecev v državah regije:{' '}
              <b>Slovenija, Italija, Avstrija, Madžarska, Hrvaška, Bosna in Hercegovina, Črna Gora, Srbija, Kosovo in Severna Makedonija</b>
            </li>
            <li>Kupon 7 € ali 13 € na vašo Petrol klub kartico</li>
            <li>Vleka vozila in prevoz potnikov na domači naslov</li>
            <li>Zagotovljeno popravilo do 200€</li>
            <li>Hotelska nastanitev</li>
            <li>In še več…</li>
          </ul>
          <CtaButton onClick={() => navigateTo(cta_2_button_link)}>Postanite član Kluba</CtaButton>
          <div className="qr-section">
            <img src="/img/petrolLanding/qr-code-2-sl.svg" alt="qr-code" />
            <p className="qr-text">Postanite član kluba</p>
          </div>
        </Wrapper>
      </MembershipSection>
      <BecomeMemberSection>
        <Wrapper>
          <h2 className="max-480">Kako do zlatih ugodnosti?</h2>

          <div className="mobile-max-480">
            <img
              className="tutorial"
              src="/img/petrolLanding/1-install-vozzi-app.png"
              alt="Telefon, na katerem je prikaz začetnega zaslona aplikacije VOZZi."
            />
            <p>1. Namestite aplikacijo VOZZi</p>
            <img
              className="tutorial"
              src="/img/petrolLanding/2-register-app.png"
              alt="Registracija v aplikaciji VOZZi.
"
            />
            <p>2. Registrirajte se</p>
            <img
              className="tutorial"
              src="/img/petrolLanding/3-click-on-banner.png"
              alt="Prikaz pasice Petrol Klub v aplikaciji VOZZi za pomoč na cesti."
            />
            <p>3. Kliknite na pasico in vnesite številko svoje Petrol klub kartice</p>
            <img className="tutorial" src="/img/petrolLanding/4-petrol-club.png" alt="VOZZi Petrol Klub Regija SLO cena pomoči na cesti." />
            <p>4. Kupite enega od VOZZi Petrol Klub paketov pomoči na cesti</p>
            <img
              className="tutorial-coupon"
              src="/img/petrolLanding/5-coupon.png"
              alt="Petrol Klub kartica zvestobe 
"
            />
            <p className="last-p">
              5. Na Petrol klub kartico boste prejeli kupon v višini 7 € ali 13 € za nakupe na Petrol bencinskih servisih
            </p>
            <CtaButton onClick={() => navigateTo(cta_3_button_link)}>Prenesite kupon</CtaButton>
          </div>

          <h2 className="min-768">Kako do zlatih ugodnosti?</h2>

          <div className="mobile-max-768">
            <div className="flex">
              <img
                src="/img/petrolLanding/1-install-vozzi-app.png"
                alt="Telefon, na katerem je prikaz začetnega zaslona aplikacije VOZZi."
              />
              <img src="/img/petrolLanding/2-register-app.png" alt="Registracija v aplikaciji VOZZi." />
            </div>
            <p>1. Namestite aplikacijo VOZZi</p>
            <p className="last-p">2. Registrirajte se</p>
            <div className="flex">
              <img src="/img/petrolLanding/3-click-on-banner.png" alt="Prikaz pasice Petrol Klub v aplikaciji VOZZi za pomoč na cesti." />
              <img src="/img/petrolLanding/4-petrol-club.png" alt="VOZZi Petrol Klub Regija SLO cena pomoči na cesti." />
            </div>
            <p>3. Kliknite na pasico in vnesite številko svoje Petrol klub kartice</p>
            <p className="last-p">4. Kupite enega od VOZZi Petrol Klub paketov pomoči na cesti</p>
            <img className="tutorial-coupon" src="/img/petrolLanding/5-coupon.png" alt="Petrol Klub kartica zvestobe" />
            <p className="last-p">
              5. Na Petrol klub kartico boste prejeli kupon v višini 7 € ali 13 € za nakupe na Petrol bencinskih servisih
            </p>
            <div className="qr-section">
              <img src="/img/petrolLanding/qr-code-3-sl.svg" alt="qr-code" />
              <p className="qr-text">Prenesite kupon</p>
            </div>
          </div>

          <div className="tablet-desktop">
            <div className="all-tutor-imgs">
              <img
                src="/img/petrolLanding/1-install-vozzi-app.png"
                alt="Telefon, na katerem je prikaz začetnega zaslona aplikacije VOZZi."
              />
              <img
                src="/img/petrolLanding/2-register-app.png"
                alt="Registracija v aplikaciji VOZZi.
"
              />
              <img src="/img/petrolLanding/3-click-on-banner.png" alt="Prikaz pasice Petrol Klub v aplikaciji VOZZi za pomoč na cesti." />
              <img src="/img/petrolLanding/4-petrol-club.png" alt="VOZZi Petrol Klub Regija SLO cena pomoči na cesti." />
              <img className="tutorial-coupon" src="/img/petrolLanding/golden-card-tutor-section.png" alt="Petrol Klub kartica zvestobe " />
            </div>
            <p>1. Namestite aplikacijo VOZZi</p>
            <p>2. Registrirajte se</p>
            <p>3. Kliknite na pasico in vnesite številko svoje Petrol klub kartice</p>
            <p>4. Kupite enega od VOZZi Petrol Klub paketov pomoči na cesti</p>
            <p className="last-p">
              5. Na Petrol klub kartico boste prejeli kupon v višini 7 € ali 13 € za nakupe na Petrol bencinskih servisih
            </p>
            <div className="qr-section">
              <img src="/img/petrolLanding/qr-code-3-sl.svg" alt="qr-code" />
              <p className="qr-text">Prenesite kupon</p>
            </div>
          </div>
        </Wrapper>
      </BecomeMemberSection>
      <WhatsIncludedSection>
        <Wrapper>
          <h2>Kaj je vključeno v  VOZZi Petrol Klub pakete pomoči na cesti?</h2>

          <div className="petrol-club domestic">
            <img
              src="/img/petrolLanding/whats-included-1.png"
              alt="Telefon, v katerega vstopa kartica VOZZi Petrol Klub SLO za pomoč na cesti."
            />

            <div>
              <h3>VOZZi Petrol Klub SLO</h3>

              <div className="middle-section-1">
                <h4>
                  Trajanje paketa: <span className="orange">12 mesecev</span>
                </h4>
                <h4>
                  Cena paketa: <span className="orange">43 EUR</span>
                </h4>
                <h4>Storitve iz paketa se zagotavljajo samo na območju Slovenije</h4>
                <h4>Storitve:</h4>
              </div>

              <ul>
                <li>Hitro popravilo na poti x2</li>
                <li>Vleka do 40 kilometrov</li>
                <li>Vleka do kraja stalnega prebivališča (ali servisa)</li>
                <li>Možnost nadgradnje paketa v VOZZi Petrol Regija</li>
              </ul>
            </div>
          </div>

          <div className="petrol-club region ">
            <img
              src="/img/petrolLanding/whats-included-2.png"
              alt="Telefon, v katerega vstopa kartica VOZZi Petrol Klub Regija SLO za pomoč na cesti."
            />

            <div>
              <h3>VOZZi Petrol Klub SLO Regija</h3>

              <div className="middle-section-2">
                <h4>
                  Trajanje paketa: <span className="orange">12 mesecev</span>
                </h4>
                <h4>
                  Cena: <span className="orange">84 EUR</span>
                </h4>

                <h4>
                  <span style={{ fontWeight: 400 }}>Storitve, vključene v paket, vključujejo države:</span> Slovenija, Italija, Avstrija,
                  Madžarska, Hrvaška, Bosna in Hercegovina, Črna Gora, Srbija, Kosovo in Severna Makedonija
                </h4>

                <h4>Storitve:</h4>
              </div>

              <ul>
                <li>Hitro popravilo na poti</li>
                <li>Vleka do najbližjega partnerskega servisa</li>
                <li>Popravilo do 200 €</li>
                <li>Hotelska nastanitev</li>
                <li>Vleka vozila in prevoz potnikov na domači naslov</li>
                <li>Nadaljevanje potovanja za vse potnike v vozilu</li>
              </ul>
            </div>
          </div>

          <CtaButton onClick={() => navigateTo(cta_4_button_link)}>Kupite VOZZi paket</CtaButton>

          <div className="qr-section">
            <img src="/img/petrolLanding/qr-code-4-sl.svg" alt="qr-code" />
            <p className="qr-text">Kupite VOZZi paket</p>
          </div>
        </Wrapper>
      </WhatsIncludedSection>
      <WhyVozziSection>
        <Wrapper>
          <h2>Zakaj VOZZi?</h2>
          <div className="boxes-wrapper">
            <div className="box">
              <div className="img-wrapper">
                <img src="/img/petrolLanding/why-vozzi-icon-1.svg" alt="qr-code" />
              </div>
              <p>
                Prva <span className="bold700">popolnoma digitalna</span> pomoč na cesti v regiji
              </p>
            </div>
            <div className="box with-euro-map">
              <div className="img-wrapper">
                <img src="/img/petrolLanding/why-vozzi-icon-2.svg" alt="qr-code" />
              </div>
              <p>
                <span className="bold700">1500+</span>
                <br />
                mreža partnerjev po <span className="bold700">vsej Evropi</span>
              </p>
            </div>
            <div className="box">
              <div className="img-wrapper">
                <img src="/img/petrolLanding/why-vozzi-icon-3.svg" alt="qr-code" />
              </div>
              <p>
                <span className="bold700">14.500+</span>
                <br /> uspešnih asistenc
              </p>
            </div>
            <div className="box">
              <div className="img-wrapper">
                <img src="/img/petrolLanding/why-vozzi-icon-4.svg" alt="qr-code" />
              </div>
              <p>
                <span className="bold700">200.000+</span>
                <br /> registriranih uporabnikov
              </p>
            </div>
            <div className="box">
              <div className="img-wrapper">
                <img src="/img/petrolLanding/why-vozzi-icon-5.svg" alt="qr-code" />
              </div>
              <p>
                Možnost <span className="bold700">nadgradnje</span> paketa
              </p>
            </div>
            <div className="box">
              <div className="img-wrapper">
                <img src="/img/petrolLanding/why-vozzi-icon-6.svg" alt="qr-code" />
              </div>
              <p>
                <span className="bold700">Brez</span> papirologije
              </p>
            </div>
            <div className="box orange-finger-box">
              <div className="img-wrapper">
                <img src="/img/petrolLanding/why-vozzi-icon-7.svg" alt="qr-code" />
              </div>
              <p>
                Vse zaključite <span className="bold700">z enim klikom</span>
              </p>
            </div>
            <div className="box qr-code">
              <img src="/img/petrolLanding/qr-code-5-sl.svg" alt="qr-code" />
              <p className="qr-text">Namestite VOZZi</p>
            </div>
          </div>
          <div className="white-finger-box">
            <img src="/img/petrolLanding/why-vozzi-icon-big-finger.svg" alt="qr-code" />
            <p>
              Vse zaključite <span className="bold700">z enim klikom</span>
            </p>
          </div>
          <CtaButton onClick={() => navigateTo(cta_5_button_link)}>Namestite VOZZi</CtaButton>
        </Wrapper>
      </WhyVozziSection>
    </NewStandardPageLayout>
  );
};

export default petrolLandingPage;
